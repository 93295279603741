export const usePreCheckout = () => {
  const { data: codes, execute: fetchPreCheckoutProducts } = useLazyFetch(
    "/api/getPreCheckoutProducts",
    {
      method: "GET",
      immediate: false
    }
  )

  const { cart, lineItems } = useCart()

  const isInCart = (code: string) =>
    lineItems.value.map((li) => li.sku_code).includes(code)

  const getPreCheckoutProducts = () =>
    cart.value && codes.value && codes.value.filter((code) => !isInCart(code))

  const preCheckoutProducts = useState<string[] | null>(`precheckout`, () => [])

  const initPrecheckoutProducts = () => {
    preCheckoutProducts.value = getPreCheckoutProducts()
  }

  return {
    preCheckoutProducts,
    fetchPreCheckoutProducts,
    initPrecheckoutProducts
  }
}
